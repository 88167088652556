// Variables
$clr-primary-1: hsl(205, 86%, 17%);
$clr-primary-2: hsl(205, 77%, 27%);
$clr-primary-3: hsl(205, 72%, 37%);
$clr-primary-4: hsl(205, 63%, 48%);
$clr-primary-5: hsl(205, 78%, 60%);
$clr-primary-6: hsl(205, 89%, 70%);
$clr-primary-7: hsl(205, 90%, 76%);
$clr-primary-8: hsl(205, 86%, 81%);
$clr-primary-9: hsl(205, 90%, 88%);
$clr-primary-10: hsl(205, 100%, 96%);
$clr-grey-1: hsl(209, 61%, 16%);
$clr-grey-2: hsl(211, 39%, 23%);
$clr-grey-3: hsl(209, 34%, 30%);
$clr-grey-4: hsl(209, 28%, 39%);
$clr-grey-5: hsl(210, 22%, 49%);
$clr-grey-6: hsl(209, 23%, 60%);
$clr-grey-7: hsl(211, 27%, 70%);
$clr-grey-8: hsl(210, 31%, 80%);
$clr-grey-9: hsl(212, 33%, 89%);
$clr-grey-10: hsl(210, 36%, 96%);
$clr-white: #fff;
$clr-red-dark: hsl(360, 67%, 44%);
$clr-red-light: hsl(360, 71%, 66%);
$clr-green-dark: hsl(125, 67%, 44%);
$clr-green-light: hsl(125, 71%, 66%);
$clr-black: #222;
$transition: all 0.3s linear;
$spacing: 0.1rem;
$radius: 0.25rem;
$light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
$dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
$max-width: 1170px;
$fixed-width: 620px;

// Global Styles
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: $clr-grey-10;
  color: $clr-grey-1;
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: $spacing;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
 
   
      margin-bottom: 1.25rem;
      color: $clr-grey-3;
    }
    
    @media screen and (min-width: 800px) {
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 1.75rem;
      }
      h4 {
        font-size: 1rem;
      }
      body {
        font-size: 1rem;
      }
      h1,
      h2,
      h3,
      h4 {
        line-height: 1;
      }
    }
    
    // Global Classes
    
    // Section
    .section {
      width: 90vw;
      margin: 0 auto;
      max-width: $max-width;
    
      @media screen and (min-width: 992px) {
        width: 95vw;
      }
    }
    
    // Quiz
    main {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .quiz {
      width: 90vw;
      max-width: $max-width;
      margin: 4rem auto;
      background: $clr-white;
      border-radius: $radius;
      padding: 3rem;
    }
    .quiz-small {
      max-width: 500px;
    }
    .container h2 {
      margin-bottom: 2rem;
      text-align: center;
      line-height: 1.5;
      text-transform: none;
    }
    .answer-btn {
      display: block;
      width: 100%;
      margin: 0.75rem auto;
      background: $clr-primary-7;
      border-radius: $radius;
      border-color: transparent;
      color: $clr-black;
      letter-spacing: $spacing;
      font-size: 1rem;
      cursor: pointer;
      padding: 0.5rem 0;
      transition: $transition;
    }
    
    @media screen and (min-width: 576px) {
      .answer-btn {
        max-width: 60%;
      }
    }
    
    .answer-btn:hover {
      background: $clr-primary-5;
      color: $clr-white;
    }
    .correct-answers {
      font-size: 1rem;
      margin-bottom: 2rem;
      text-align: right;
      text-transform: capitalize;
      letter-spacing: $spacing;
      color: $clr-green-dark;
    }
    .next-question,
    .close-btn,
    .submit-btn2 {
      border-radius: $radius;
      border-color: transparent;
      padding: 0.25rem 0.5rem;
      display: block;
      width: 100%;
      margin-left: auto;
      margin-top: 2rem;
      text-transform: capitalize;
      font-weight: 700;
      letter-spacing: $spacing;
      font-size: 1rem;
      background: #00ff33;
      color: $clr-black;
      transition: $transition;
      cursor: pointer;
    }
    .next-question:hover {
      background: #805900;
      color: #ffb100;
    }
    
    // Form
    .setup-form h2 {
      margin-bottom: 2rem;
    }
    .form-control {
      margin-bottom: 2rem;
    }
    .form-control label {
      display: block;
      text-transform: capitalize;
      font-weight: 500;
      color: $clr-grey-3;
      margin-bottom: 0.5rem;
    }
    
    .form-input {
      border: none;
      background: $clr-grey-10;
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
      width: 100%;
      border-radius: $radius
      
}
.error {
  color: $clr-red-dark;
  text-transform: capitalize;
}
.submit-btn {
  width: 100%;
  margin-top: 3rem;
}

// Loading
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: $clr-primary-5;
  animation: spinner 0.6s linear infinite;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: $transition;
  z-index: -1;
}
.isOpen {
  opacity: 1;
  z-index: 999;
}

.modal-content {
  background: $clr-white;
  width: 90vw;
  max-width: $fixed-width;
  padding: 3rem;
  border-radius: $radius;
  text-align: center;
  position: relative;
}
.modal-content p {
  font-size: 1.5rem;
  text-transform: none;
}
.close-btn {
  margin-right: auto;
}

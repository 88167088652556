

* {
    box-sizing: border-box;
}


.cardDashBoard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}


.dash-header {
    height: 30vh;
    width: 100%;
    background-image: url('../../assets/images/library1.png');
    background-size: cover; /* Adjusts the image to cover the entire div */
    background-position: center; /* Centers the image in the div */

}

.dash-body {
    background-color: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    padding: 20px;
    padding-top: 30px; /* Add top padding to create space between the dash-header and the first row of folders */

}

.folder-box {
    width: calc(20% - 20px); /* Adjust size for 5 boxes per row, considering margin */
    height: 120px; /* Adjust size as needed */
    border: 1px solid #ccc; /* Add a border */
    margin: 20px 20px; /* Reduce top and bottom margin, keep left and right margin */
    padding: 10px; /* Padding inside the box */
    text-align: center; /* Center the text and icon */

    /* 3D Effect */
    box-shadow:
            5px 5px 10px rgba(0, 0, 0, 0.2), /* Outer shadow */
            inset 1px 1px 2px rgba(255, 255, 255, 0.5), /* Top left inner highlight */
            inset -1px -1px 2px rgba(0, 0, 0, 0.4); /* Bottom right inner shadow */
    transition: transform 0.2s ease; /* Smooth transition for a "click" effect */
}

.folder-box img {
    width: 50%; /* Adjust icon size as needed */
    height: 50%;
    margin-bottom: 10px; /* Space between icon and text */
    margin-top :10px ;
}
.folder-box:hover {
    transform: translateY(-5px); /* Slight move up on hover for interactive 3D effect */
    cursor: pointer; /* Changes the cursor to a pointer to indicate interactivity */

}

.folder-box:active {
    transform: scale(0.95);
    transition: transform 0.1s;

}
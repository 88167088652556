.languageSwitcher {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.languageButton {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Customize focus ring color */
  }
}

.english {
  background-color: #007BFF;
  color: white;
}

.korean {
  background-color: #FFC107;
  color: white;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 60px; /* Further reduced width */
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 1px 3px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px; /* Reduced font size */
}

/* Show tooltip text when hovering */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

* {
    box-sizing: border-box;
}

.cardDashBoard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.dash-header {
    height: 30vh;
    width: 100%;
    background-image: url('../../assets/images/bookshelf.png');
    background-size: cover; /* Adjusts the image to cover the entire div */
    background-position: center; /* Centers the image in the div */
}

.dash-body {
    background-color: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
    padding-top: 30px; /* Add top padding to create space between the dash-header and the first row of folders */
}

.folder-box {
    background-color: #fff; /* Set the folder box background to white */
    width: calc(20% - 20px);
    height: 120px;
    border: 1px solid #ccc;
    margin: 20px 20px;
    padding: 10px;
    text-align: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
    inset 1px 1px 2px rgba(255, 255, 255, 0.5),
    inset -1px -1px 2px rgba(0, 0, 0, 0.4);
    transition: transform 0.2s ease;
}

.folder-box img {
    width: 50%;
    height: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: transparent; /* Ensure no background is added by CSS */
}


.folder-box:hover {
    transform: translateY(-5px); /* Slight move up on hover for interactive 3D effect */
    cursor: pointer; /* Changes the cursor to a pointer to indicate interactivity */
}

.folder-box:active {
    transform: scale(0.95);
    transition: transform 0.1s;
}

.folder-box.selected {
    border: 2px solid #007BFF; /* Blue border when selected */
    background-color: #E9F5FF; /* Light blue background */
    transform: scale(1.05); /* Slightly enlarge the selected folder */
}









.modal_button {
    margin: 30px ; 
}


.card-index {
    margin: 30px ; 
}
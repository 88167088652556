@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

$main-font: 'Poppins', sans-serif;
$main-bg-color: #b8c6db;
$main-bg-gradient: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 100%);
$white-color: #fff;
$shadow-color: rgba(100, 100, 100, 0.1);
$button-bg-color: skyblue;
$button-text-color: white;



.container2  {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.quiz-container {
		display: flex;


		align-items: center;
		justify-content: center;

		width: 60%;
		height: 60%;

		background-color: $white-color;
		border-radius: 10px ;
		box-shadow: 0 0 10px 2px $shadow-color;
		width: 600px;
		overflow: hidden;
	}
	.card-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;

		.card-index {
			font-size: 1rem;
			margin-bottom: 2rem;
			text-align: left;
			text-transform: capitalize;
			letter-spacing: var(--spacing);
			color: blue;
		}

	}


	.quiz-header {
		// insert styles for quiz-header here
	}

	.word_form {
		text-align: center ;
		font-size: 1.5rem ;
		padding: 7rem;
	}
	.word_content {
		max-height: 500px; // You can set this to whatever you prefer

		overflow-y: auto; // Enable vertical scroll
		width: 120%; // Setting width to 80% of the parent container

	}

	.button-container {
		display: none;


		.button {
			display: inline;
			background-color: $button-bg-color;
			color: $button-text-color;
			border: none;
			border-radius: 7px;
			font-size: 16px;
			cursor: pointer ;
			width: 100%;
			padding: 10px 20px;
			margin-top: 10px;
		}

	}
	.none-display {
		display: none;
	}

	.flex-display {
		display: flex;
	}

}
	


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

$main-font: 'Poppins', sans-serif;
$main-bg-color: #b8c6db;
$main-bg-gradient: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 100%);
$white-color: #fff;
$shadow-color: rgba(100, 100, 100, 0.1);
$button-bg-color: skyblue;
$button-text-color: white;



	.container2  {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	
		.quiz-container {
			display: flex;

			flex-direction: column; // Add this to stack child elements vertically

			align-items: center;
			justify-content: flex-start; // Aligns items to the top

			width: 60%;
			height: 80%;

			background-color: $white-color;
			border-radius: 10px ; 
			box-shadow: 0 0 10px 2px $shadow-color;
			//width: 600px;
			overflow: hidden;
		}
		.card-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start; // Aligns items to the left
			width: 100%; // Add this to occupy full width
			padding-top: 1rem;  // Add padding at the top to prevent clipping

			.card-index {
				font-size: 0.6rem;
				margin: 0.2rem 0.5rem; // Reduce margin to prevent it from going too far outside
				text-align: left;
				text-transform: capitalize;
				letter-spacing: var(--spacing);
				color: blue;
				padding: 5px; // Optional padding
				background-color: rgba(0, 0, 255, 0.1); // Optional background color
				border-radius: 50%; // Optional rounded corners
			}
		
		}

	
		.quiz-header {
			// insert styles for quiz-header here
		}
	
		.word_form {
			text-align: center ; 
			font-size: 1.5rem ; 
			 padding: 7rem;

		}
		.word_content {
			max-height: 500px; // You can set this to whatever you prefer

			overflow-y: auto; // Enable vertical scroll
			width: 120%; // Setting width to 80% of the parent container

		}

		.button-container {
			display: flex;
			justify-content: center;
			/* Add other styles for the container if necessary */
			width: 600px;  /* Increase this value as needed */
			height: 60px;
			margin-top: 20px;

		}

		.button {
			display: inline-block;
			background-color: $button-bg-color;
			color: $button-text-color;
			border: none;
			border-radius: 7px;
			font-size: 16px;
			cursor: pointer;
			padding: 10px 20px;
			margin-right: 30px;  /* Add space to the right of each button */
			margin-left: 30px;
		}

		.none-display {
			display: none;
		  }
		  
		  .flex-display {
			display: flex;
		  }
	
	}
	

